import axios from "axios";
import router from "../router";
import Qs from 'qs';
import md5 from 'js-md5';
let Base64 = require('js-base64').Base64;

axios.defaults.baseURL =
  process.env.NODE_ENV == "development" ? "" : process.env.VUE_APP_BASE_API;
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.timeout = 120000;
axios.defaults.withCredentials = true //让ajax携带cookie
// 添加请求拦截器
function getUrlParams(val) {
  let urlStr = val.split('?')[1]
    // 创建空对象存储参数
	let obj = {};
    // 再通过 & 将每一个参数单独分割出来
	let paramsArr = urlStr.split('&')
	for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
		let arr = paramsArr[i].split('=')
		obj[arr[0]] = arr[1];
	}
	return obj
}
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断vuex中是否存在tokenSaen
    // 如果存在，则统一在http请求的header都加上tokenSaen，这样后台根据tokenSaen判断你的登录情况
    // 即使本地存在tokenSaen，也有可能tokenSaen是过期的，所以在响应拦截器中要对返回状态进行判断
    //config.headers.Authorization = tokenSaen值
    const tokenSaen = localStorage.getItem("tokenSaen");
    const timestamp = new Date().valueOf();
    config.headers.deviceId = "6";
    config.headers.timestamp = timestamp;
    // console.log(Qs.parse(config.data));
    let newStr = {};
    if(config.url.indexOf("/api/frame/v1/common/smscode/send") != -1){
      newStr =getUrlParams(config.url);
    }
    var ps = Qs.parse(newStr);
    var p_arr = new Array();
    for (const p in ps) {
      p_arr.push(p+ps[p]);
    }

    p_arr.sort();
    //console.log("base64(1717663553191) ", Base64.encode('1717663553191') );
    var sign_str = Base64.encode(timestamp.toString()); 
    //var sign_str = Base64.encode('1717663553191'); 
    if (tokenSaen != null && tokenSaen != "") {
      config.headers.token = tokenSaen;
      
      sign_str = sign_str;
    }
    sign_str = sign_str+'385CD0C8-4FBC-4BDD-9B62-F6382170A779';
    var p_str = '';
    for(var i =0;i<p_arr.length;i++){
      p_str+=p_arr[i];
    }
    sign_str = sign_str + p_str;
    config.headers.signature = md5(sign_str);
    return config;
  },
  function (error) {
    // console.log('对请求错误做些什么');
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // console.log('response---------------',response)
    if (response.data && response.data.code == -1) {
      localStorage.setItem("tokenSaen", "");
      localStorage.setItem("userInfo", "");
      localStorage.setItem("replaceUrl", window.location.href);
      router.replace({
        path: "/login",
      });
    }
    return response;
  },
  function (error) {
    // console.log('对响应错误做点什么');
    return Promise.reject(error);
  }
);

export default axios;
